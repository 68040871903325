.container {
  width: 100%;
  height: 100vh;
  text-align: center;
}

.title {
  margin-top: 24px;
  padding: 24px 0;

  color: black;

  font-family: Pretendard;
  font-size: 28px;
  font-weight: 700;
  line-height: 32px; /* 114.286% */
  letter-spacing: -0.56px;
}

.wrap {
  width: 50%;
  margin: 0 auto;
  gap: 8px;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.attachment-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.item-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 32px;
  gap: 6px;
}

.item-wrap label {
  color: #778197;
  text-align: left;

  font-family: Pretendard;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px; /* 125% */
  letter-spacing: -0.48px;
}

.item-wrap input {
  padding: 8px 16px;
  align-items: center;
  gap: 8px;

  border-radius: 8px;
  border: 1px solid #CCD2DD;
  background: white;

  color: #323A49;

  font-family: Pretendard;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px; /* 125% */
  letter-spacing: -0.48px;
}

.link {
  display: flex;
}

.link p {
  width: 190px;
  text-decoration: none;

  cursor: pointer;

  padding: 6px 8px 8px;
  border-radius: 6px;
  background-color: #f3f3f3;

  &:hover {
    color: blue;
    font-weight: 500;
    background-color: #e9e9e9;
  }
}

.item-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: left;
  color: #323A49;
}

.attachment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;

  width: 100%;
  height: 250px;
  border-radius: 8px;
  border: 2px dashed #000000;

  padding: 12px;

  &:hover {
    background-color: #f3f3f3;
  }
}

.file-name-wrap {
  width: 100%;
  padding: 16px;
}

.calendar {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.calendar-input p {
  margin: 0;
  color: #778197;
  text-align: left;

  font-family: Pretendard;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px; /* 123.077% */
  letter-spacing: -0.39px;
}

.date {
  display: inline-flex;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;

  border-radius: 8px;
  border: 1px solid #CCD2DD;
  background: white;

  color: #323A49;

  font-family: Pretendard;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px; /* 125% */
  letter-spacing: -0.48px;

  cursor: pointer;

  &:hover {
    background-color: #F5F6FA;
  }
}

.bottom-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 0;

  gap: 16px;
}

.download-btn {
  width: 200px;

  display: flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  
  color: white;
  /* color: var(--torderGray-800, #323A49); */
  background-color: #F53839 ;

  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.72px;

  cursor: pointer;

  &:hover {
    transition: background 0.1s;
    background-color: rgba(246, 56, 57, 0.84); 
  }
}

.upload-btn {
  width: 200px;

  display: flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  
  color: white;
  /* color: var(--torderGray-800, #323A49); */
  background-color: #000000 ;

  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.72px;

  cursor: pointer;

  &:hover {
    transition: background 0.1s;
    background-color: rgba(41, 41, 41, 0.84); 
  }
}